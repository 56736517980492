<template>
	<div>
		Index
		<button @click="$router.push('/user/about')">Click to Navigate</button>

		<router-view/>
	</div>
</template>

<script>
	export default {};
</script>
